import alexander from "../assets/img/trombinoscope/alexander.jpg";
import manon from "../assets/img/trombinoscope/manon.jpg";
import lucas from "../assets/img/trombinoscope/lucas.jpg";
import Emma from "../assets/img/trombinoscope/emma.jpg";
import léonie from "../assets/img/trombinoscope/léonie.jpg";
import alberrique from "../assets/img/trombinoscope/alberrique.jpg";
import hermann from "../assets/img/trombinoscope/hermann.jpg";
import claire from "../assets/img/trombinoscope/claire.jpg";
import loic from "../assets/img/trombinoscope/loic.jpg";
import maiwen from "../assets/img/trombinoscope/maiwen.jpg";
import thibaut from "../assets/img/trombinoscope/thibaut.jpg";
import idalie from "../assets/img/trombinoscope/idalie.jpg";
import rayna from "../assets/img/trombinoscope/rayna.jpg";
import maxence from "../assets/img/trombinoscope/maxence.jpg";
import eric from "../assets/img/trombinoscope/eric.jpg";

const members = [
  {
    name: "Manon",
    role: "Présidente",
    image: manon,
  },
  {
    name: "Alexander",
    role: "Secrétaire",
    image: alexander,
  },
  {
    name: "Lucas",
    role: "Trésorier",
    image: lucas,
  },
  {
    name: "Emma",
    role: "Fouaille",
    image: Emma,
  },
  {
    name: "Léonie",
    role: "Fouaille",
    image: léonie,
  },
  {
    name: "Alberrique",
    role: "Fouaille",
    image: alberrique,
  },
  {
    name: "Hermann",
    role: "Fouaille",
    image: hermann,
  },
  {
    name: "Claire",
    role: "Int",
    image: claire,
  },
  {
    name: "Loïc",
    role: "Alternance",
    image: loic,
  },
  {
    name: "Maïwen",
    role: "Réseaux",
    image: maiwen,
  },
  {
    name: "Thibaut",
    role: "Tech",
    image: thibaut,
  },

  {
    name: "Idalie",
    role: "Spons",
    image: idalie,
  },
  {
    name: "Rayna",
    role: "Spons",
    image: rayna,
  },

  {
    name: "Maxence",
    role: "Ext",
    image: maxence,
  },
  {
    name: "Eric",
    role: "Ext",
    image: eric,
  },
];

export default members;
