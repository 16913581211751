import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Tdb = () => {
  return (
    <>
      <Navbar />
      <Container>
        <svg
          width="3086"
          height="3510"
          viewBox="0 0 3086 3510"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M744.986 1114.12C735.058 1112.88 703.088 1032.87 696.724 1022.68C649.957 947.746 593.181 876.172 571.305 788.669C561.357 748.876 549.69 700.317 559.557 659.441C573.006 603.723 632.959 566.088 675.133 532.434C801.293 431.761 970.182 399.713 1127.59 399.713C1288.7 399.713 1408.28 493.306 1447.65 648.328C1481.95 783.383 1486.08 938.593 1376.84 1038.55C1281.42 1125.87 1150.36 1152.56 1025.03 1159.53C978.859 1162.09 927.378 1141.6 880.883 1135.71C844.793 1131.15 809.999 1118.96 773.88 1114.44C763.457 1113.14 749.89 1118.22 744.986 1108.41"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1939.48 1182.71C1886.99 1182.71 1830.75 1152.97 1785.17 1130C1730.84 1102.62 1665.61 1063.84 1639.74 1005.22C1588.43 888.895 1592.09 745.956 1590.85 621.339C1590.68 604.197 1557.55 386.775 1571.16 382.885C1593.36 376.542 1612.69 360.701 1634.03 351.133C1697.89 322.506 1768.28 306.057 1836.6 292.393C1939.9 271.733 2087.19 269.213 2156.34 362.564C2191.93 410.603 2212.88 468.418 2242.39 520.051C2283.95 592.779 2319.12 671.221 2333.83 754.06C2344.05 811.613 2360.18 873.793 2348.44 932.504C2333.21 1008.67 2263.03 1088.34 2191.27 1116.66C2098.91 1153.12 1991.94 1154.13 1893.76 1154.13"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1373.67 1011.25C1373.67 1220.8 1369.98 1430.42 1370.49 1639.93C1370.97 1835.93 1380.78 2041.24 1423.83 2233.05C1433.13 2274.45 1509.51 2213.91 1531.79 2204.47C1661.67 2149.42 1786.77 2078.18 1856.29 1950.46C1917.98 1837.12 1931.44 1709.12 1947.73 1583.41C1956.41 1516.5 1954.26 1443.26 1942.34 1377.03C1935.93 1341.43 1926.37 1303.98 1916.94 1270.98C1905.98 1232.64 1909.37 1187.99 1899.47 1148.41"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1314.38 1661.36C1329.87 1688.11 1344.1 1653.09 1362.3 1644.69C1388.66 1632.52 1419.74 1639.71 1445.11 1623.86C1469.22 1608.79 1480.91 1620.25 1507.09 1626.98C1552.73 1638.72 1591.17 1609.73 1633.65 1600.94C1685.34 1590.24 1714.37 1646.07 1755 1668.65C1789.1 1687.59 1820.48 1683.16 1853.96 1699.9C1877.9 1711.87 1940.8 1708.23 1970.62 1708.23"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1390.81 2017.14C1535.9 2017.14 1680.97 2005.71 1825.17 2005.71"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1680 1605.11C1680 1744.34 1680 1883.58 1680 2022.81C1680 2052.72 1699.82 2185.28 1670.63 2214.47"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M2133.8 708.338C2137.38 676.105 2178.45 636.527 2195.71 609.908C2205.41 594.968 2215.46 580.124 2225.24 565.456"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M2025.21 371.136V542.595"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1848.04 382.567V519.734"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1076.47 651.185C1074.46 667.265 888.414 656.901 859.292 656.901"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1698.75 1445.73C1632.64 1445.73 1619.46 1354.69 1605 1307.71C1598.66 1287.1 1577.23 1258.47 1594.59 1239.49C1621.46 1210.09 1629.82 1178.96 1653.96 1146.78C1710.45 1071.46 1608.49 1059.67 1558.65 1019.18C1527.86 994.158 1530.67 932.627 1530 896.781C1529.37 862.639 1522.9 851.611 1558.13 845.74"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M859.292 868.366C762.003 868.366 665.006 874.081 567.812 874.081"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M933.591 1011.25C911.008 1016.27 880.286 1054.4 859.292 1068.4"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1076.47 776.921C1076.47 876.982 1053.61 974.455 1053.61 1074.12"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1783.13 1445.73C1783.13 1398.86 1783.13 1351.98 1783.13 1305.11C1783.13 1239.51 1839.37 1216.26 1839.37 1150.42C1839.37 1106.57 1847.94 1073.81 1801.88 1054.07C1763.03 1037.42 1733.38 938.132 1726.88 892.615"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1345.09 451.15V788.352"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1122.19 525.449C1083.03 486.288 1040.32 430.214 990.743 405.428"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M607.82 514.019H865.008"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1511.25 1623.86C1468.85 1560.25 1465.06 1507.66 1450.32 1436.88C1433.17 1354.56 1478.57 1317.79 1506.57 1246.26C1530.94 1183.96 1551.21 1135.55 1484.17 1093.65C1451.46 1073.21 1417.5 1033.23 1408.13 995.738"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M693.549 725.484C689.274 682.728 636.396 659.033 636.396 611.178"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1842.32 776.921C1906.9 776.921 1963.2 796.529 2025.21 813.753C2073.15 827.07 2121.88 836.999 2169.68 850.903C2184.97 855.352 2208.85 857.686 2219.53 868.366"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M2076.65 1062.69C2108.65 1062.69 2133.4 1072.37 2162.38 1085.55"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1876.61 811.213C1874.12 736.344 1839.56 660.682 1819.78 588.952C1809.75 552.617 1800 516.195 1790.88 479.727"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M2279.99 48.8745C2162.76 57.8926 2047.07 76.2169 1928.96 76.9991C1733.52 78.2934 1547.75 28.1551 1352.92 38.9787C1146.82 50.4286 959.121 163.259 759.179 211.893C696.428 227.157 634.868 245.289 571.681 259.289C513.38 272.206 455.834 288.255 397.725 301.996C368.763 308.846 328.826 310.834 301.893 324.913C263.188 345.145 220.102 404.56 194.082 438.974C151.646 495.099 133.802 541.014 133.145 611.368C132.435 687.363 150.779 727.531 179.499 795.741C224.951 903.69 226.894 1008.23 226.894 1123.86C226.894 1229.54 240.233 1345.97 216.477 1449.9C192.58 1554.45 170.525 1654.81 163.353 1762.4C157.596 1848.74 158.843 1937.69 166.478 2023.85C174.559 2115.06 198.692 2251.07 265.956 2320.2C326.455 2382.38 394.709 2435.65 445.641 2508.22C516.54 2609.24 543.861 2718.42 634.181 2808.74C775.61 2950.17 920.094 3125.4 1120.63 3184.25C1243.9 3220.43 1365.52 3264.64 1487.82 3304.04C1588.24 3336.4 1686.62 3372.13 1784.17 3412.38C1859.15 3443.32 1940.01 3490.28 2020.62 3506.13C2059.54 3513.78 2090.57 3491.49 2119.58 3468.63C2193.77 3410.15 2260.69 3338.46 2327.39 3271.75C2480.62 3118.52 2622.57 2922.95 2721.66 2729.57C2804.99 2566.94 2892.65 2396.05 2932.07 2216.56C2958.91 2094.33 2979.41 1970.36 3005.51 1847.81C3036.13 1704.02 3031.89 1554.48 3029.99 1408.23C3027.23 1195.67 2981.36 1007.6 2894.57 814.49C2799.17 602.219 2752.59 369.902 2641.45 165.54C2606.54 101.345 2582.24 51.1267 2533.12 2"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M873.761 2223.85C854.704 2228.61 914.005 2235.14 926.365 2250.41C965.744 2299.06 998.291 2354.25 1014.91 2414.47C1050.34 2542.93 1066.06 2704.88 1209.17 2763.43C1338.96 2816.52 1530.37 2835.69 1653.96 2751.97C1689.41 2727.95 1724.66 2707.44 1756.04 2678.53C1797.23 2640.59 1839.51 2646.83 1887.29 2625.93C1973.7 2588.13 2073.07 2539.87 2134.16 2466.55C2190.73 2398.67 2234.98 2322.22 2274.27 2243.64C2296.07 2200.03 2336.24 2152.03 2336.24 2101.98"
            stroke="#367CBC"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M3048.24 1782.81C3048.24 1696 3045.16 1608.56 3048.56 1521.81C3050.93 1461.31 3070.14 1405.49 3079.36 1346.23C3087.2 1295.82 3082.54 1242.47 3082.54 1191.6C3082.54 1095.39 3082.54 999.182 3082.54 902.975C3082.54 872.813 3059.58 810.327 3031.1 795.337C3004.57 781.375 2971.53 782.637 2942.51 782.637C2918.98 782.637 2921.76 845.568 2918.06 862.651C2905 923.076 2926.32 1005.53 2945.37 1062.69"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M156.313 1908.55C151.82 1859.13 116.84 1804.07 96.3021 1759.95C60.9685 1684.05 41.3784 1602.39 28.0363 1519.91C12.3069 1422.67 2 1321.64 2 1223.03C2 1185.95 14.2908 1155.91 36.2917 1128.41C51.2673 1109.69 93.949 1082.61 119.481 1091.9C142.424 1100.24 149.683 1158.66 162.028 1178.26C180.203 1207.13 184.979 1248.53 207.75 1274.15C233.209 1302.79 230.611 1374.8 230.611 1411.32"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M442.076 474.012C498.173 474.012 572.315 446.409 622.743 424.797C733.039 377.527 840.037 332.067 956.451 302.553C1022.54 285.798 1082.25 268.261 1150.77 268.261"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M1596.56 245.4C1788.29 245.4 1978.69 228.255 2170.63 228.255C2226.93 228.255 2286.24 221.914 2339.55 239.685"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 30px;
  gap: 10px;
  margin-top: 50px;

  svg {
    max-height: 100vh;
    max-width: 100%;
  }
`;

export default Tdb;
